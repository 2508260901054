import {
    IoStar, IoArrowForwardSharp
} from "react-icons/io5";
import {
    Button,
    Box,
    Avatar,
    Card,
    ListItemButton,
    ListItemText,
    List,
    IconButton,
    Collapse,
    TextField,
    Rating,
    Typography,
    Fab,
    Stack
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Review from "../../components/review/Review";
import Ratings from "../../components/ratings/Ratings";
import Iconify from "../../components/iconify/Iconify";
import AppDialog from "../../components/app-dialog/AppDialog";
import "./About.css";
import Rightbar from "../rightbar/Rightbar";
import EnquiryForm from "../../components/enquiry-form/EnquiryForm";
import { useDispatch, useSelector } from "react-redux";
import { SHOW_LOGING_DIALOG } from "../../reduxStore/actions/AuthTypes";
import { Helmet } from "react-helmet";
import AppButton from "../../components/app-button/AppButton";
import ReviewFilters from "../../components/review-filters/ReviewFilters";
import { addRecommendation, addStoreReview, deleteReview, deleteStoreReview, filterStoreReview, getReviewRecommendation, sendStoreEnquiry, updateStoreReview } from "../../reduxStore/actions/StoreActions";
import { useSnackbar } from "notistack";
import { IMAGE_URL, NO_STORE_IMAGE } from "../../constants/Images";
import FaqList from "../../components/faq-list/FaqList";
import PhotoList from "../../components/photo-list/PhotoList";
import FsLightbox from "fslightbox-react";
import AppDialogDelete from "../../components/app-dialog-delete/AppDialogDelete";

function About(props) {
    const authSelector = useSelector((state) => state.sAuth);
    const accessToken = authSelector?.userDetails?.access_token;
    const uid = authSelector?.userDetails?.unique_id;
    const storeSelector = useSelector((state) => state.sStore);
    const site = storeSelector.site;
    const configs = site?.store_details?.theme_config ? JSON.parse(site?.store_details?.theme_config) : {};
    const allReviews = storeSelector.reviews;
    const storeId = site?.store_details?.uid;
    const allFaqs = site?.store_details?.faqs ? JSON.parse(site?.store_details?.faqs) : [];
    const allPhotos = site?.photos ? site?.photos : [];
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [faqOpen, setFaqOpen] = React.useState(false);
    const [aboutOpen, setAboutopen] = React.useState(false);
    const [addReviewOpen, setAddReviewOpen] = React.useState(false);
    const [dialogSendEnqOpenSm, setDialogSendEnqOpenSm] = React.useState(false);
    const [addReviewCount, setAddReviewCount] = React.useState(0);
    const [addReviewDesc, setAddReviewDesc] = React.useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isRecLoading, setIsRecLoading] = useState(false);
    const [toggler, setToggler] = React.useState(false);
    const [lightImages, setLightImages] = React.useState([]);
    const [activeLabelId, setActiveLabelId] = React.useState('');
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [activeUid, setActiveUid] = React.useState('');
    const [activeEditUid, setActiveEditUid] = React.useState('');
    const [activeFilter, setActiveFilter] = useState('all');

    const [formValues, setFormValues] = useState({
        name: '',
        mobile_no: '',
        city: '',
        email: '',
        message: '',
        store_id: storeId
    });
    console.log("activeLabelId", activeLabelId);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const handleDialogClickOpen = () => {
        setDialogOpen(true);
    };
    const handleDialogClickClose = () => {
        setDialogOpen(false);
        setAboutopen(false);
        setAddReviewOpen(false);
        setDialogSendEnqOpenSm(false);
        setAddReviewCount(0);
        setAddReviewDesc("");
        emptyFields();
    };

    const handleSendEnqDialogClickOpen = () => {
        setDialogSendEnqOpenSm(true);
    };

    const handleAboutClickOpen = () => {
        setAboutopen(true);
    };
    const handleAddReviewClickOpen = () => {
        if (accessToken) {
            if (Object.keys(site?.reviews?.user_review ? site?.reviews?.user_review : {}).length !== 0) {
                handleEditClick(site?.reviews?.user_review);
            } else {
                setAddReviewOpen(true);
            }
        } else {
            dispatch({
                type: SHOW_LOGING_DIALOG,
                payload: true
            });
        }
    };
    const addReview = async () => {
        if (addReviewCount === 0) {
            enqueueSnackbar('Rating star are mandatory.', { variant: 'error' });
            return;
        }
        try {
            setIsLoading(true);
            const obj = JSON.stringify({
                store_id: storeId,
                rating: addReviewCount,
                user_review: addReviewDesc,
                device_type: 'Website'
            });
            const res = await dispatch(addStoreReview(obj));
            await dispatch(getReviewRecommendation({ store_id: storeId }));
            setIsLoading(false);
            setAddReviewCount(0);
            setAddReviewOpen(false);
            enqueueSnackbar(res.data.message, { variant: 'success' });
        } catch (err) {
            setIsLoading(false);
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    };


    const handleEditClick = async (review) => {
        setActiveEditUid(review?.uid);
        setAddReviewCount(review?.rating);
        setAddReviewDesc(review?.user_review);
        setAddReviewOpen(true);
    }


    const updateReview = async () => {
        if (addReviewCount === 0) {
            enqueueSnackbar('Rating star are mandatory.', { variant: 'error' });
            return;
        }
        try {
            setIsLoading(true);
            const obj = JSON.stringify({
                store_id: storeId,
                rating: addReviewCount,
                user_review: addReviewDesc,
                uid: activeEditUid
            });
            const res = await dispatch(updateStoreReview(obj));
            await dispatch(getReviewRecommendation({ store_id: storeId }));
            setIsLoading(false);
            cancelReview();
            enqueueSnackbar(res.data.message, { variant: 'success' });
        } catch (err) {
            setIsLoading(false);
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    };

    const cancelReview = () => {
        setAddReviewOpen(false);
        setAddReviewCount(0);
        setAddReviewDesc("");
        setActiveEditUid("");
    };

    const recommendClick = async () => {
        if (accessToken) {
            try {
                setIsRecLoading(true);
                const obj = JSON.stringify({
                    store_id: storeId,
                    record_status: site?.recommendation?.user_recommendation && site?.recommendation?.user_recommendation === 'Y' ? 'N' : 'Y',
                    uid: uid
                });
                const res = await dispatch(addRecommendation(obj));
                await dispatch(getReviewRecommendation({ store_id: storeId }));
                setIsRecLoading(false);
                enqueueSnackbar(res.data.message, { variant: 'success' });
            } catch (err) {
                setIsRecLoading(false);
                if (err?.errors) {
                    enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
                }
            }
        } else {
            dispatch({
                type: SHOW_LOGING_DIALOG,
                payload: true
            });
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const sendEnquiry = async () => {
        try {
            setIsLoading(true);
            const obj = JSON.stringify(formValues);
            const res = await dispatch(sendStoreEnquiry(obj));
            setIsLoading(false);
            setDialogSendEnqOpenSm(false);
            emptyFields();
            enqueueSnackbar(res.data.message, { variant: 'success' });
        } catch (err) {
            setIsLoading(false);
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    };

    const cancelEnquiry = () => {
        setDialogSendEnqOpenSm(false);
        emptyFields();
    };

    const emptyFields = () => {
        setFormValues({
            name: '',
            mobile_no: '',
            city: '',
            email: '',
            message: '',
            store_id: storeId
        })
    }

    const onFsClose = () => {
        setLightImages([]);
        console.log("close");
    }
    const openImageViwer = (lightImages, labelID) => {
        console.log("toggler", toggler);
        console.log("lightImages", lightImages);
        setActiveLabelId(labelID);
        setToggler(!toggler);
        setLightImages(lightImages);
    }

    const getUserReviewRecommendation = async () => {
        await dispatch(getReviewRecommendation({ store_id: storeId }));
    }

    const cancelReply = () => {
        setActiveEditUid('');
        setActiveUid('');
    };

    const handleDeleteClose = async () => {
        setIsDeleteDialogOpen(false);
    }

    const handleDeleteClick = async (row) => {
        setIsDeleteDialogOpen(true);
        setActiveUid(row?.uid);
    }

    const handleDeleteConfirm = async () => {
        setIsDeleteDialogOpen(false);
        const obj = JSON.stringify({
            store_id: storeId,
            uid: activeUid,
            record_status: 'D'
        });
        try {
            const res = await dispatch(deleteStoreReview(obj));
            await dispatch(getReviewRecommendation({ store_id: storeId }));
            setActiveUid("");
            enqueueSnackbar(res.data.message, { variant: 'success' });
        } catch (err) {
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    }

    //Review Filters
    const filterReviews = async (value) => {
        console.log("value", value);
        setActiveFilter(value);
        let filterParams;
        switch (value) {
            case 'all':
                filterParams = "5,4,3,2,1";
                break;
            case 'positive':
                filterParams = "5,4";
                break;
            case 'critical':
                filterParams = "1,2";
                break;
            case '5':
                filterParams = "5";
                break;
            case '4':
                filterParams = "4";
                break;
            case '3':
                filterParams = "3";
                break;
            case '2':
                filterParams = "2";
                break;
            case '1':
                filterParams = "1";
                break;
        }
        try {
            setIsLoading(true);
            const obj = JSON.stringify({
                store_id: storeId,
                filter_para: filterParams
            });
            const res = await dispatch(filterStoreReview(obj));
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    }

    useEffect(() => {
        if (accessToken) {
            getUserReviewRecommendation();
        }
    }, [storeId]);

    const dialogEnqFooter = (
        <>
            <Box style={{
                display: 'flex',
                gap: 15,
            }}>
                <AppButton
                    size='medium'
                    onClick={cancelEnquiry}
                    style={{
                        color: configs?.primaryButtonBgColor
                    }}
                >
                    Cancel
                </AppButton>
                <AppButton
                    onClick={sendEnquiry}
                    disabled={isLoading}
                    isLoading={isLoading}
                    variant="contained"
                    style={{
                        backgroundColor: configs?.primaryButtonBgColor,
                        color: configs?.primaryButtonColor,
                        minWidth: 100
                    }}
                    size='medium'>
                    Submit
                </AppButton>
            </Box>
        </>
    )

    const dialogTitle = (
        <ReviewFilters activeFilter={activeFilter} filterReviews={filterReviews} />
    );

    const dialogAboutTitle = (
        <>
            <div className='reviewDialogHeader'>
                <div className='reviewDialogImg'>
                    <Avatar
                        variant="square"
                        alt={site?.store_details?.store_name}
                        src={site?.store_details?.logo ? `${IMAGE_URL}store/logo/thumbnail?id=${storeId}&img_id=${site?.store_details.logo}` : NO_STORE_IMAGE}
                        style={{
                            height: 60,
                            width: 60,
                            borderRadius: 10
                        }}
                    />
                </div>
                <div className='reviewDialogName'>
                    <div>{site?.store_details?.store_name}</div>
                    <div style={{
                        fontSize: 14,
                        fontWeight: 400
                    }}>About store</div>
                </div>
            </div>
        </>
    );
    const dialogAddReviewTitle = (
        <>
            <div className='reviewDialogHeader'>
                <div className='reviewDialogImg'>
                    <Avatar
                        variant="square"
                        alt={site?.store_details?.store_name}
                        src={site?.store_details?.logo ? `${IMAGE_URL}store/logo/thumbnail?id=${storeId}&img_id=${site?.store_details.logo}` : NO_STORE_IMAGE}
                        style={{
                            height: 60,
                            width: 60,
                            borderRadius: 10
                        }}
                    />
                </div>
                <div className='reviewDialogName'>
                    <div>{site?.store_details?.store_name}</div>
                    <div style={{
                        fontSize: 14,
                        fontWeight: 400
                    }}>Rate this store</div>
                </div>
            </div>
        </>
    );

    const dialogAddReviewFooter = (
        <>
            <Box style={{
                display: 'flex',
                gap: 15,
            }}>
                <Button
                    onClick={cancelReview}
                    size='medium'
                    style={{
                        color: configs?.primaryButtonBgColor
                    }}
                >
                    Cancel
                </Button>
                <AppButton
                    onClick={activeEditUid ? updateReview : addReview}
                    disabled={isLoading}
                    isLoading={isLoading}
                    variant="contained"
                    style={{
                        backgroundColor: configs?.primaryButtonBgColor,
                        color: configs?.primaryButtonColor,
                        minWidth: 100
                    }}
                    size='medium'>Submit</AppButton>
            </Box>
        </>
    )

    const viewOnMap = () => {
        const address = `${site?.store_details?.address}, ${site?.store_details?.area}, ${site?.store_details?.city} ${site?.store_details?.pincode}`;
        window.open(`https://www.google.com/maps/search/${address}`, "_blank");
    }

    return (
        <>
            <Helmet>
                <style>{`body { background-color: ${configs?.pageBackground} }`}</style>
                <style>
                    {`.tabStyle.Mui-selected { background-color: ${configs?.themeBgColor}; color: ${configs?.themeColor} }`}
                </style>
                <title>{site?.store_details?.store_name ? `About ${site?.store_details?.store_name}` : 'About'}</title>
            </Helmet>
            <Box className='page-container page-container-about v1-store-about'>
                <Box className="about-page-wrap">
                    <Box className="v1-about-main-content">
                        <Card className='v1-store-card-w' style={{
                            width: '100%'
                        }}>
                            <Box className='v1-store-about-container'>
                                <Box className='v1-store-about-head'>
                                    <Avatar
                                        variant="square"
                                        alt={site?.store_details?.store_name}
                                        src={site?.store_details?.logo ? `${IMAGE_URL}store/logo/thumbnail?id=${storeId}&img_id=${site?.store_details.logo}` : NO_STORE_IMAGE}
                                        className='v1-store-av'
                                    />
                                    <Box className='v1-store-name-wrap'>
                                        <Typography className='v1-store-name'>
                                            {site?.store_details?.store_name}
                                        </Typography>

                                        {site?.store_details?.area &&
                                            <Box style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 5,
                                                flexWrap: 'wrap'
                                            }}>
                                                <Iconify icon={'material-symbols:location-on-outline'} />
                                                <Typography style={{
                                                    fontSize: 13
                                                }}>
                                                    {site?.store_details?.area}
                                                </Typography>
                                            </Box>
                                        }

                                        {/* <Box className='time-wrap'>
                                            <Box>
                                                <span className='time-txt'>OPEN:</span> 06:00am - 10:00pm(Today)
                                            </Box>
                                            <Iconify icon={'ic:baseline-arrow-drop-down'} style={{
                                                height: 30,
                                                width: 30
                                            }} />
                                        </Box> */}
                                    </Box>
                                </Box>
                                <Box className='v1-store-about-actions'>
                                    <ul>
                                        <li style={{
                                            minWidth: 85
                                        }}>
                                            <Box style={{
                                                display: 'flex',
                                                gap: 2,
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                flexWrap: 'wrap'
                                            }}>
                                                <Box>{site?.reviews?.rating ? site?.reviews?.rating : 0}</Box>
                                                <Iconify icon={'ic:outline-star'} />
                                            </Box>
                                            <Box className='subText'>
                                                {site?.reviews?.reviewCount ? site?.reviews?.reviewCount : 0} reviews
                                            </Box>
                                        </li>
                                        <li>
                                            <Box>{site?.recommendation?.recommendationCount ? site?.recommendation?.recommendationCount : 0}</Box>
                                            <Box className='subText'>Recommended</Box>
                                        </li>
                                        {site?.store_details?.contact_no &&
                                            <li>
                                                <a href={`tel:+91${site?.store_details?.contact_no}`}>
                                                    <Box><Iconify icon={'material-symbols:call'} /></Box>
                                                    <Box className='subText'>Call</Box>
                                                </a>
                                            </li>
                                        }
                                        {site?.store_details?.whatsapp_no &&
                                            <li>
                                                <a href={`//api.whatsapp.com/send?phone=91${site?.store_details?.whatsapp_no}&text=Hi`}>
                                                    <Box><Iconify icon={'ic:round-whatsapp'} /></Box>
                                                    <Box className='subText'>WhatsApp</Box>
                                                </a>
                                            </li>
                                        }

                                        <li style={{
                                            minWidth: 60
                                        }}>
                                            <a onClick={viewOnMap} style={{
                                                cursor: "pointer"
                                            }}>
                                                <Box><Iconify icon={'material-symbols:map-outline'} /></Box>
                                                <Box className='subText'>View map</Box>
                                            </a>
                                        </li>
                                    </ul>
                                </Box>
                                <Box className='v1-store-about-ac-btns'>
                                    <Box>
                                        {!accessToken &&
                                            <AppButton
                                                isLoading={isRecLoading}
                                                onClick={recommendClick} style={{
                                                    backgroundColor: configs?.primaryButtonBgColor,
                                                    color: configs?.primaryButtonColor
                                                }} variant='contained'>
                                                Recommend
                                            </AppButton>
                                        }
                                        {accessToken && (site?.recommendation?.user_recommendation &&
                                            site?.recommendation?.user_recommendation === 'N') &&
                                            <AppButton
                                                isLoading={isRecLoading}
                                                onClick={recommendClick} style={{
                                                    backgroundColor: configs?.primaryButtonBgColor,
                                                    color: configs?.primaryButtonColor
                                                }} variant='contained'>
                                                Recommend
                                            </AppButton>
                                        }
                                        {accessToken && (site?.recommendation?.user_recommendation &&
                                            site?.recommendation?.user_recommendation === 'Y') &&
                                            <AppButton
                                                startIcon={<Iconify icon={'material-symbols:check-small-rounded'} />}
                                                className="recommended-btn"
                                                isLoading={isRecLoading}
                                                onClick={recommendClick}
                                                variant='contained'>
                                                Recommended
                                            </AppButton>
                                        }
                                    </Box>
                                    {configs?.showReviews &&
                                        <Box>
                                            <AppButton style={{
                                                color: configs?.primaryButtonBgColor
                                            }} onClick={handleAddReviewClickOpen}>
                                                {accessToken && Object.keys(site?.reviews?.user_review ? site?.reviews?.user_review : {}).length !== 0 && 'Edit a review'}
                                                {(!accessToken || Object.keys(site?.reviews?.user_review ? site?.reviews?.user_review : {}).length === 0) && 'Write a review'}
                                            </AppButton>
                                        </Box>
                                    }
                                </Box>
                                <Box className='divider'></Box>
                                <Box className='sAbout'>
                                    <Box className='sTitle'>
                                        <Box>Location</Box>
                                    </Box>
                                    <Box className='sBody sAddress'>
                                        <Box>
                                            <Typography className='SBodyText'>
                                                {site?.store_details?.address}, {site?.store_details?.area}, {site?.store_details?.city} {site?.store_details?.pincode}
                                            </Typography>
                                            <Typography style={{
                                                fontSize: 13,
                                                color: '#6a5e5e'
                                            }}>
                                                {site?.store_details?.landmark ? `Landmark: ${site?.store_details?.landmark}` : ''}
                                            </Typography>
                                        </Box>
                                        <Typography style={{
                                            width: 170
                                        }}>
                                            <AppButton onClick={viewOnMap} style={{
                                                color: configs?.primaryButtonBgColor
                                            }}>Get Direction</AppButton>
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box className='divider'></Box>
                                <Box className='sAbout'>
                                    <Box className='sTitle'>
                                        <Box>About</Box>
                                        <Box>
                                            <IconButton onClick={handleAboutClickOpen}>
                                                <IoArrowForwardSharp size={20} color="#000" />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                    <Box className='sBody'>
                                        <Typography className='SBodyText'>
                                            {site?.store_details?.about}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box className='divider'></Box>

                                {configs?.showReviews &&
                                    <Box>
                                        <Box className='sAbout'>
                                            {accessToken && Object.keys(site?.reviews?.user_review ? site?.reviews?.user_review : {}).length !== 0 &&
                                                <Box>
                                                    <Box className='sReview'>
                                                        <Box>Your Review</Box>
                                                    </Box>
                                                    <Box>
                                                        <Box>
                                                            <Box>
                                                                <Review
                                                                    isUserReview={true}
                                                                    review={site?.reviews?.user_review}
                                                                />
                                                            </Box>
                                                        </Box>
                                                        <Stack direction='row' spacing={2} style={{
                                                            marginTop: 10,
                                                            marginLeft: 10
                                                        }}>
                                                            <Button
                                                                onClick={() => handleEditClick(site?.reviews?.user_review)}
                                                                size="small"
                                                                variant="text"
                                                                startIcon={<Iconify icon="eva:edit-fill" />} style={{
                                                                    color: 'rgb(0, 171, 85)'
                                                                }}>
                                                                Edit
                                                            </Button>
                                                            <Button
                                                                onClick={() => handleDeleteClick(site?.reviews?.user_review)}
                                                                size="small"
                                                                variant="text"
                                                                startIcon={<Iconify icon="eva:trash-2-outline" />}
                                                                style={{
                                                                    color: '#FF4842'
                                                                }}>
                                                                Delete
                                                            </Button>
                                                        </Stack>
                                                    </Box>
                                                </Box>
                                            }

                                            {(!accessToken || Object.keys(site?.reviews?.user_review ? site?.reviews?.user_review : {}).length === 0) &&
                                                <Box>
                                                    <Box className='sReview'>
                                                        <Box>Rate this store</Box>
                                                        <Box className='sReviewSub'>Tell others what you think.</Box>
                                                    </Box>
                                                    <Box className='sBody'>
                                                        <Box className='sWriteReview'>
                                                            <Box>
                                                                <Rating
                                                                    value={addReviewCount}
                                                                    onClick={handleAddReviewClickOpen}
                                                                    size="large"
                                                                    onChange={(event, newValue) => {
                                                                        if (accessToken) {
                                                                            setAddReviewCount(newValue);
                                                                        }
                                                                    }}
                                                                />
                                                            </Box>
                                                            <Box>
                                                                <AppButton onClick={handleAddReviewClickOpen} style={{
                                                                    color: configs?.primaryButtonBgColor
                                                                }}>
                                                                    Write Review
                                                                </AppButton>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            }
                                        </Box>
                                        <Box className='sRatingDisplay'>
                                            <Box className='sTitle'>
                                                <Box>Ratings and reviews</Box>
                                                <Box>
                                                    <IconButton onClick={handleDialogClickOpen}><IoArrowForwardSharp size={20} color="#000" /></IconButton>
                                                </Box>
                                            </Box>
                                            <Box className='sBody'>
                                                <Ratings />
                                            </Box>
                                        </Box>
                                        {site?.reviews?.reviews?.length > 0 &&
                                            <Box className='sUserReviews'>
                                                {site?.reviews?.reviews.map((review, index) => {
                                                    if (index < 8) {
                                                        return (
                                                            <Review
                                                                review={review}
                                                                key={review.uid}
                                                            />
                                                        )
                                                    }
                                                })}
                                                <Box className='sReviewSeeAll'>
                                                    <AppButton
                                                        style={{
                                                            color: configs?.primaryButtonBgColor
                                                        }}
                                                        onClick={handleDialogClickOpen}
                                                        size='small'>
                                                        See All Reviews
                                                    </AppButton>
                                                </Box>
                                            </Box>
                                        }
                                        <Box className='divider'></Box>
                                    </Box>
                                }

                                <Box className='sAbout'>
                                    <Box className='sTitle'>
                                        <Box>Photos</Box>
                                    </Box>
                                    <Box style={{
                                        margin: '0px 20px'
                                    }}>
                                        <Box className='sPhotoBody'>
                                            {allPhotos?.length > 0 && allPhotos.map((row) => {
                                                if (row.images.length > 0) {
                                                    return (
                                                        <PhotoList
                                                            openImageViwer={openImageViwer}
                                                            key={row?.uid}
                                                            row={row} />
                                                    )
                                                }
                                            })}
                                        </Box>
                                        <Box style={{
                                            marginBottom: 20
                                        }}></Box>
                                    </Box>
                                    <Box className='divider'></Box>
                                </Box>


                                <Box className='sAbout'>
                                    <Box className='sTitle'>
                                        <Box>Frequently Asked Question</Box>
                                    </Box>
                                    <Box style={{
                                        margin: '0px 20px'
                                    }}>
                                        {allFaqs?.length > 0 && allFaqs.map((row) => {
                                            return (
                                                <FaqList
                                                    key={row?.id}
                                                    row={row} />
                                            )
                                        })}
                                        <Box style={{
                                            marginBottom: 20
                                        }}></Box>
                                    </Box>
                                </Box>
                                <Box className='fab-en-wrap'>
                                    <Fab className="fab-en" style={{
                                        background: configs?.themeBgColor,
                                        color: configs?.themeColor
                                    }} onClick={handleSendEnqDialogClickOpen}>
                                        <Iconify icon={'material-symbols:comment-outline'} />
                                    </Fab>
                                </Box>
                            </Box>
                        </Card>
                    </Box>
                    <Box className='v1-store-rightmenu'>
                        <Rightbar />
                    </Box>
                </Box>
            </Box>

            {dialogSendEnqOpenSm &&
                <AppDialog
                    isOpen={dialogSendEnqOpenSm}
                    id={'store_right_send_enq'}
                    handleClickClose={handleDialogClickClose}
                    title={<Box>Send Enquiry</Box>}
                    footer={dialogEnqFooter}
                >
                    <EnquiryForm formVals={formValues} handleChange={handleChange} />
                </AppDialog>
            }


            {dialogOpen &&
                <AppDialog
                    isOpen={dialogOpen}
                    id={'store_review_sm'}
                    handleClickClose={handleDialogClickClose}
                    title={dialogTitle}>
                    {allReviews && allReviews?.map((review) => {
                        return (
                            <Review
                                review={review}
                                key={review?.uid}
                            />
                        )
                    })}
                </AppDialog>
            }
            {aboutOpen &&
                <AppDialog
                    isOpen={aboutOpen}
                    id={'store_about_sm'}
                    handleClickClose={handleDialogClickClose}
                    title={dialogAboutTitle}>
                    <Box style={{
                        padding: 15
                    }}>
                        <Typography style={{
                            fontSize: 15,
                        }}>
                            {site?.store_details?.about}
                        </Typography>
                    </Box>
                </AppDialog>
            }
            {addReviewOpen &&
                <AppDialog
                    isOpen={addReviewOpen}
                    id={'store_add_review_sm'}
                    handleClickClose={handleDialogClickClose}
                    title={dialogAddReviewTitle}
                    footer={dialogAddReviewFooter}
                >
                    <Box style={{
                        padding: 15
                    }}>
                        <Box>
                            <Typography>Rating & Review</Typography>
                            <Box className="store-rating-dialog-wrap"
                            >
                                <Rating
                                    value={addReviewCount}
                                    size="large"
                                    onChange={(event, newValue) => {
                                        setAddReviewCount(newValue);
                                    }} />
                            </Box>
                        </Box>
                        <Box style={{
                            marginTop: 15
                        }}>
                            <TextField
                                value={addReviewDesc}
                                onChange={(event) => setAddReviewDesc(event.target.value)}
                                fullWidth
                                multiline rows={2}
                                label="Describe your experience (optional)" />
                        </Box>
                    </Box>
                </AppDialog>
            }

            {isDeleteDialogOpen &&
                <AppDialogDelete isOpen={isDeleteDialogOpen} id={'delete_review'} title={<>Delete Review</>}
                    footer={
                        <>
                            <Button autoFocus onClick={handleDeleteConfirm} className='confirm-delete-btn'>
                                Delete
                            </Button>
                            <Button onClick={handleDeleteClose} variant='outlined' className='cancel-delete-btn'>
                                Cancel
                            </Button>
                        </>}
                >
                    <Typography style={{
                        fontSize: 14,
                        color: '#000'
                    }}>Are you sure want to delete review?</Typography>
                </AppDialogDelete>
            }

            <FsLightbox
                onClose={onFsClose}
                toggler={toggler}
                sources={lightImages}
                key={activeLabelId}
            />

        </>
    );
}

export default About;